import React from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, colors } from '@material-ui/core';
import IconAlternate from 'components/molecules/IconAlternate';
import DescriptionListIcon from 'components/organisms/DescriptionListIcon';
import SectionHeader from 'components/molecules/SectionHeader';
import Section from 'components/organisms/Section';
// import { IconAlternate, SectionHeader } from 'components/molecules';
// import { DescriptionListIcon, Section } from 'components/organisms';

const useStyles = makeStyles(() => ({
  fontWeight900: {
    fontWeight: 900,
  },
  noPaddingBottom: {
    paddingBottom: 0,
  },
  noPaddingTop: {
    paddingTop: 0,
  },
}));

const data = [
  {
    icon: 'fas fa-bolt',
    title: 'Fast Online and Off',
    subtitle:
      'Manage thousands of documents with seemless offline support.',
  },
  {
    icon: 'fas fa-indent',
    title: 'Outlines & Smart Notes',
    subtitle:
      "Turn bulleted lists into rich nested documents and create your own personal wiki."
  },
  {
    icon: 'fas fa-project-diagram',
    title: 'Build Connections',
    subtitle:
      "Easily create #refernces between documents and build new connections."
  },
  {
    icon: 'fas fa-sitemap',
    title: 'Catagorize and Filter',
    subtitle:
      'Built for speed and lightning fast.  Manage thousands of documents with seemless offline support.',
  },
  {
    icon: 'fas fa-crown',
    title: 'Personal Project Managment',
    subtitle:
      'Transform nested checklists into Kanaban items.  Customize your workflow and track rollup progress.',
  },
  {
    icon: 'fas fa-comment-alt',
    title: 'Collaboration & Chat',
    subtitle:
      "Never lose track of document comments with integrated chat channels. Keep the conversation in context and stop managing multiple apps.",
  },
];


const Features = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const title = (
    <Typography variant="h2" component="span" className={classes.fontWeight900}>
      Simplicity and Power
    </Typography>
  );

  const subtitle = 'Grow your ideas with a modern personal knowledge base.';

  return (
    <div className={className} {...rest}>
      <Section narrow className={classes.noPaddingBottom}>
        <SectionHeader
          title={title}
          subtitle={subtitle}
          align="center"
          titleProps={{
            variant: 'h2',
            color: 'textPrimary',
          }}
          data-aos="fade-up"
        />
      </Section>
      <Section className={classes.noPaddingTop}>
        <Grid container spacing={isMd ? 4 : 2}>
          {data.map((item, index) => (
            <Grid key={index} item xs={12} sm={4} data-aos={'fade-up'}>
              <DescriptionListIcon
                title={item.title}
                subtitle={item.subtitle}
                icon={
                  <IconAlternate
                    fontIconClass={item.icon}
                    size="medium"
                    color={colors.indigo}
                  />
                }
                align="left"
              />
            </Grid>
          ))}
        </Grid>
      </Section>
    </div>
  );
};

export default Features;
