import { Button, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Image from 'components/atoms/Image';
// import { SectionHeader } from 'components/molecules';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import heroLogo from 'assets/images/hero-logo.png'
import SectionHeader from 'components/molecules/SectionHeader';



const useStyles = makeStyles(theme => ({
  root: {
    // maxWidth: '1020px',
    paddingLeft: '32px',
    paddingRight: '32px',
    position: 'relative',
    margin: 'auto',
    maxWidth: '1200px',
    [theme.breakpoints.down("sm")]: {
      paddingTop: '0px',
    },
    [theme.breakpoints.up("md")]: {
      // paddingTop: '10vh',
    },
  },
  content: {
    position: 'relative',
    zIndex: 1,
  },
  imageWrap: {
    margin: 'auto !important',
    height: '632px'
  },
  imageBox: {
    margin: 'auto !important',
    // height: '632px',
    width: '1000px',
    justifyContent: 'center',
    textAlign: 'center',
    // display: 'inline-flex',
    // mb: [0, null, -6, null, null, '-40px', null, -3],
    // width: ['100%', null, null, 720, '100%', 1000],
    // width: '100%',
    marginTop: 'auto',
    display: 'block',
    marginBottom: 'auto',
    '& img': {
      position: 'relative',
      height: [245, 'auto'],
    },
  },
  img: {
    // boxShadow: '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    maxWidth: 1010,
    minWidth: 624,
    [theme.breakpoints.down('sm')]: {
      // maxWidth: 500,
    },
  },
  header: {
    // width: '68%',
    margin: 'auto',
    marginBottom: theme.spacing(2),
    '& h3': {
      fontSize: '1.8rem',
    }
  },
  title: {
    paddingBottom: '30px'
  },
  logoImage: {
    [theme.breakpoints.up("md")]: {
      maxWidth: '500px',
      height: '500px',
      margin: '-90px auto',
    },

    [theme.breakpoints.down("sm")]: {
      width: '100%',
      height: 'auto',
    },
  },
}));

const Hero = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const dataThumb = useStaticQuery(graphql`
  query {
    placeholderImage: file(relativePath: { eq: "banner-thumb.png" }) {
      childImageSharp {
        fluid(maxWidth: 1010) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`);





  return (
    <div className={clsx(className, classes.root,)} {...rest}>
      <div className={classes.content}>
        <Grid
          container
          justify="space-between"
          spacing={4}
        // direction={isMd ? 'row' : 'column-reverse'}
        >
          <Grid
            item
            container
            alignItems="center"
            md={12}
            data-aos={'fade-up'}
          >
            <Image className={classes.logoImage} src={heroLogo} alt="Lightmap" lazy={false} />

            <SectionHeader
              title='The modern way to manage your ideas.'
              titleProps={{ className: classes.title }}
              className={classes.header}
              // Transforms your notes and todo lists into a rich personal wiki-project, and get the most out of what you know"
              // subtitle="Transform your notes and todo lists into a rich personal wiki-project, and get the most out of what you know."
              ctaGroup={[
                <Button variant="contained" color="primary" size="large" href="/sign-up" >
                  Sign up for free
                </Button>,
                // <Button variant="outlined" color="primary" size="large">
                //   Learn more
                // </Button>,
              ]}
              align="center"
              disableGutter
              titleVariant="h3"
            />
          </Grid>
          {/* <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src="https://assets.maccarianagency.com/the-front/illustrations/dashboard-screenshot.jpg"
            alt="TheFront Company"
            className={classes.image}
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          />
        </Grid> */}
          <Grid
            item
            container
            // justify="flex-start"
            alignItems="center"
            md={12}
          // data-aos={'fade-up'}
          >
            <div className={classes.imageBox}>
              <Img fluid={dataThumb.placeholderImage.childImageSharp.fluid} />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Hero;
