import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, Button } from '@material-ui/core';
// import LearnMoreLink from 'components/atoms/LearnMoreLink';
import Icon from 'components/atoms/Icon';
import SectionHeader from 'components/molecules/SectionHeader';
import CardPricingStandard from 'components/organisms/CardPricingStandard';

const useStyles = makeStyles(() => ({
  fontWeight900: {
    fontWeight: 900,
  },
}));

const Pricings = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title="Simple Pricing"
        subtitle="No hidden cost and we won't hold your data hostage."
        // ctaGroup={[ <LearnMoreLink title="See what's included" href="#" variant="h6" />, ]}
        data-aos="fade-up"
      />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={6} data-aos="fade-up">
          <CardPricingStandard
            variant="outlined"
            withShadow
            liftUp
            title="Free"
            subtitle="For Invididuals and Small Teams."
            priceComponent={
              <div>
                <Typography
                  variant="h3"
                  component="span"
                  className={classes.fontWeight900}
                >
                  $0
                </Typography>
                <Typography component="span" variant="subtitle1">
                  / MO
                </Typography>
              </div>
            }
            features={[
              'Document Editor and Kanban View',
              'Unlimited Bookmarks and Labels',
              'Full Offline Support',
              'Free Cloud Storage for your first 3000 documents',
              'Publish Your Personal Micro-Wiki',
              'Share and Edit With Up to 3 Colaborators',
              'Limited Version and Chat History',
            ]}
            featureCheckComponent={
              <Icon
                fontIconClass="far fa-check-circle"
                fontIconColor={theme.palette.primary.main}
              />
            }
            cta={
              <Button
                color="primary"
                variant="contained"
                fullWidth
                size="large"
                href="/sign-up"
              >
                Join Today
              </Button>
            }
          // disclaimer="Fully featured 30-day free trial"
          />
        </Grid>
        <Grid item xs={12} md={6} data-aos="fade-up">
          <CardPricingStandard
            variant="outlined"
            title="Pro"
            // liftUp
            subtitle="For Power Users and Small Busniess"
            // featureTitleProps={{ className: classes.featureTitle }}
            priceComponent={
              <div>
                <Typography
                  variant="h3"
                  component="span"
                  className={classes.fontWeight900}
                >
                  $5
                </Typography>
                <Typography component="span" variant="subtitle1">
                  / MO
                </Typography>
              </div>
            }
            features={[
              'All Features',
              'Mind-Map and Calendar View',
              '10GB Cloud Stroage',
              'Unlimited Collaborators & Custom Permissions',
              'Personal Wiki Analytics',
              'Full Version & Chat History',
              'Integrations',
            ]}
            featureCheckComponent={
              <Icon
                fontIconClass="far fa-check-circle"
                fontIconColor={theme.palette.primary.main}
              />
            }
            cta={
              <Button disabled color="primary" variant="outlined" fullWidth size="large">
                Comming Soon
              </Button>
            }
          // disclaimer="Fully featured 30-day free trial"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Pricings;
