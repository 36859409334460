/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 * 
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React, { createContext } from 'react';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import DesktopApp from 'views/DesktopApp';
import { Location } from 'history';
import SEO from 'components/common/SEO';

import SEOLandinImage from 'assets/images/banner-thumb.png'

export const LocationContext = createContext<Partial<Location>>({})

const IndexPage = ({ location }): JSX.Element => {
  return (
    <LocationContext.Provider value={location}>
      <SEO
        title='Lightmap'
        description="Lightmap is the modern way to manage your ideas. Transforms your notes and todo lists into a rich personal wiki-project, and get the most out of what you know."
        image={SEOLandinImage}
      />
      <WithLayout component={DesktopApp} layout={Main} />
    </LocationContext.Provider>
  )
};

export default IndexPage;
