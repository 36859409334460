import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors, Divider } from '@material-ui/core';

// import { reviews, support, integrations } from './data';
import ShapeLeft from 'assets/images/shape-left.png';
import ShapeRight from 'assets/images/shape-right.png'
import Features from './components/Features'
import SectionAlternate from 'components/organisms/SectionAlternate';
import Pricings from './components/Pricings';
import Hub from './components/Hub';
import Section from 'components/organisms/Section';
import Hero from './components/Hero';

const useStyles = makeStyles(theme => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(20),
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  heroSection: {
    position: 'relative',
    padding: '0px',
    '&::before': {
      position: 'absolute',
      content: '""',
      bottom: 6,
      left: 0,
      height: '100%',
      width: '100%',
      zIndex: 0,
      backgroundImage: `url(${ShapeLeft})`,
      backgroundRepeat: `no-repeat`,
      backgroundPosition: 'bottom left',
      backgroundSize: '36%',
    },
    '&::after': {
      position: 'absolute',
      content: '""',
      bottom: '40px',
      right: 0,
      height: '100%',
      width: '100%',
      zIndex: 0,
      backgroundImage: `url(${ShapeRight})`,
      backgroundRepeat: `no-repeat`,
      backgroundPosition: 'bottom right',
      backgroundSize: '32%',
    },
  },
  shape: {
    // background: theme.palette.alternate.main,
    // borderBottomRightRadius: '50%',
    // borderBottom: `1px solid ${colors.grey[200]}`,
  },
}));

const DesktopApp = (): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      {/* <div className={classes.shape}> */}
      <Section id='home' className={classes.heroSection} fullWidth>
        <Hero />
      </Section>
      <Section id='features' className={classes.sectionNoPaddingTop}>
        <Features />
      </Section>
      {/* <Section className={classes.sectionNoPaddingTop}>
        <Reviews data={reviews} />
      </Section> */}
      <SectionAlternate className={classes.sectionNoPaddingTop}>
        <Hub />
      </SectionAlternate>
      {/* </div> */}
      {/* <Section narrow>
        <Support data={support} />
      </Section> */}
      {/* <SectionAlternate>
        <Customization />
      </SectionAlternate> */}
      {/* <Section>
        <Partners data={integrations} />
      </Section> */}
      <SectionAlternate id='pricing'>
        <Pricings />
      </SectionAlternate>
      {/* <Section>
        <Download data={[]} />
      </Section> */}
      <Divider />
    </>
  );
};

export default DesktopApp;
