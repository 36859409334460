import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Toolbar,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  Popover,
  Typography,
  IconButton,
  Button,
  Link,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import { DarkModeToggler } from 'components/atoms';
import { Logo } from './Logo';
import { Link as ScrollLink } from 'react-scroll';
import { LocationContext } from '../../../../../pages';

const useStyles = makeStyles(theme => ({
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    width: '150px',
    display: 'flex',
    justifyContent: 'space-between',
    '& li': {
      display: 'flex',
      flexDirection: 'row-reverse',
    }
  },
  scrollNavigationContainer: {
    display: 'flex',
    margin: 'auto',
    '& li': {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    },
    '& a': {
      // fontSize: 2,
      // fontWeight: 'body',
      color: theme.palette.text.primary,
      fontSize: '18px',
      paddingLeft: '5px',
      paddingRight: '5px',
      // px: 5,
      cursor: 'pointer',
      // lineHeight: '1.2',
      transition: 'all 0.15s',
      '&:hover': {
        color: theme.palette.primary.main,
      },
      '&.active': {
        color: theme.palette.primary.main,
      },
    }
  },
  toolbar: {
    zIndex: 999,
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  navLink: {
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  listItem: {
    cursor: 'pointer',
    '&:hover > .menu-item, &:hover svg': {
      color: theme.palette.primary.dark,
    },
    '&.menu-item--no-dropdown': {
      paddingRight: 0,
    },
  },
  listItemActive: {
    '&> .menu-item': {
      color: theme.palette.primary.dark,
    },
  },
  listItemText: {
    flex: '0 0 auto',
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
  listItemButton: {
    whiteSpace: 'nowrap',
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  popover: {
    padding: theme.spacing(4),
    border: theme.spacing(2),
    boxShadow: '0 0.5rem 2rem 2px rgba(116, 123, 144, 0.09)',
    minWidth: 350,
    marginTop: theme.spacing(2),
  },
  iconButton: {
    marginRight: theme.spacing(2),
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    color: theme.palette.primary.dark,
  },
  logoContainer: {
    width: 'auto',
    height: '32px',
    [theme.breakpoints.up('md')]: {
      width: 150,
      height: 32,
    },
  },
  logoImage: {
    width: 'auto',
    height: '100%',
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItem: {
    marginRight: theme.spacing(5),
    '&:last-child': {
      marginRight: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
  },
}));

interface Props {
  className?: string;
  onSidebarOpen: Function,
  pages: PagesProps;
  themeMode: string;
  themeToggler: Function;
  isSticky: boolean;
};


const Topbar = ({ themeMode, themeToggler, onSidebarOpen, pages, className, isSticky, ...rest }: Props): JSX.Element => {
  const classes = useStyles();
  const location = useContext(LocationContext)

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [openedPopoverId, setOpenedPopoverId] = useState<string | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>, popoverId: string | null): void => {
    setAnchorEl(event.target);
    setOpenedPopoverId(popoverId);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  const supportedPages = pages.pages;
  const account = pages.account;

  const MenuGroup = ({ item }: MenuGroupProps): JSX.Element => (
    <List disablePadding>
      <ListItem disableGutters>
        <Typography
          variant="body2"
          color="primary"
          className={classes.menuGroupTitle}
        >
          {item.groupTitle}
        </Typography>
      </ListItem>
      {item.pages.map((page, i) => (
        <ListItem disableGutters key={i} className={classes.menuGroupItem}>
          <Typography
            variant="body1"
            component={'a'}
            href={page.href}
            className={clsx(classes.navLink, 'submenu-item')}
            color="textSecondary"
            onClick={handleClose}
          >
            {page.title}
          </Typography>
        </ListItem>
      ))}
    </List>
  );

  const SupportedPages = (): JSX.Element => {
    const {
      career,
      helpCenter,
      company,
      contact,
      blog,
      portfolio,
    } = supportedPages.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={career} />
          <MenuGroup item={helpCenter} />
        </div>
        <div className={classes.menuItem}>
          <MenuGroup item={company} />
          <MenuGroup item={contact} />
        </div>
        <div className={classes.menuItem}>
          <MenuGroup item={blog} />
          <MenuGroup item={portfolio} />
        </div>
      </div>
    );
  };

  const AccountPages = (): JSX.Element => {
    const { settings, signup, signin, password, error } = account.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={settings} />
        </div>
        <div className={classes.menuItem}>
          <MenuGroup item={signup} />
          <MenuGroup item={signin} />
        </div>
        <div className={classes.menuItem}>
          <MenuGroup item={password} />
          <MenuGroup item={error} />
        </div>
      </div>
    );
  };

  const renderPages = (id: string): JSX.Element | null => {
    if (id === 'supported-pages') {
      return <SupportedPages />;
    }
    if (id === 'account') {
      return <AccountPages />;
    }
    return null;
  };

  const quickNavItems = [
    {
      path: 'home',
      label: 'Home',
    },
    {
      path: 'features',
      label: 'Features',
    },
    {
      path: 'pricing',
      label: 'Pricing',
    },
  ]

  return (
    <Toolbar disableGutters className={classes.toolbar} {...rest}>
      {isSticky &&
        <div className={classes.logoContainer}>
          <Logo logoImage={classes.logoImage} themeMode={themeMode} />
        </div>
      }
      {/* <div className={classes.flexGrow} /> */}
      {/* <div className={classes.flexGrow} /> */}
      <Hidden smDown>
        {location.pathname == '/' &&
          <List disablePadding className={classes.scrollNavigationContainer}>
            {quickNavItems.map((navItem, i) => (
              <ListItem aria-describedby={navItem.label} key={navItem.label}>
                <ScrollLink
                  activeClass="active"
                  to={navItem.path}
                  spy={true}
                  smooth={true}
                  offset={i == 0 ? -100 : 0} //At index 0 we want to scroll up to the top so the nav bar re-expands -JoeP
                  duration={500}
                  key={i}
                >
                  {navItem.label}
                </ScrollLink>
              </ListItem>
            ))}
          </List>
        }
        {/* <div className={classes.flexGrow} /> */}
        {isSticky &&
          <List disablePadding className={classes.navigationContainer}>
            <ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
              <Button
                variant="outlined"
                color="primary"
                component="a"
                // target="blank"
                href="/sign-in"
                className={classes.listItemButton}
              >
                Sign In
              </Button>
            </ListItem>
            <ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
              <Button
                variant={isSticky ? "contained" : "outlined"}
                color="primary"
                component="a"
                // target="blank"
                href="/sign-up"
                className={classes.listItemButton}
              >
                Sign up
              </Button>
            </ListItem>
          </List>}
      </Hidden>
      <Hidden mdUp>
        <div className={classes.flexGrow} />
        {/* <DarkModeToggler themeMode={themeMode} onClick={() => themeToggler()} /> */}
        <IconButton
          className={classes.iconButton}
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
        >
          <MenuIcon />
        </IconButton>
      </Hidden>
    </Toolbar>
  );
};

export default Topbar;
