import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
// import { SectionHeader, CountUpNumber } from 'components/molecules';

// import ListDemo from 'assets/images/list-demo.png'
import ListDemo from 'assets/images/frame_safari_light.png'
import KanabanDemo from 'assets/images/kanban-demo.png'
import SectionHeader from 'components/molecules/SectionHeader';

const useStyles = makeStyles(theme => ({
  placementGrid: {
    display: 'flex',
  },
  placementGridItemMiddle: {
    margin: `0 ${theme.spacing(3)}px`,
  },
  coverImage: {
    // boxShadow: '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    width: '130%',
    marginLeft: '-38%',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      marginLeft: 'inherit',
    },
  },

  coverImageThumb: {
    position: 'absolute',
    bottom: '-30px',
    // boxShadow: '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    width: '600px',
    height: 'auto',
    marginLeft: '100px',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      position: 'relative',
      maxWidth: '100%',
      marginLeft: 'inherit',
    },
  },
}));

const Features = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={className} {...rest} style={{ position: 'relative' }}>
      <Grid container spacing={4}>
        <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          xs={12}
          md={8}
        // data-aos="fade-up"
        >
          <Image
            src={ListDemo}
            // "https://assets.maccarianagency.com/the-front/illustrations/dashboard-screenshot1.jpg"
            alt="..."
            className={classes.coverImage}
            data-aos="fade-right"
            data-aos-easing="ease-out-cubic"
          // data-aos-duration="2000"
          />
          <Image
            src={KanabanDemo}
            // "https://assets.maccarianagency.com/the-front/illustrations/dashboard-screenshot1.jpg"
            alt="..."
            className={classes.coverImageThumb}
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
          // data-aos-duration="2000"
          />
        </Grid>
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          xs={12}
          md={4}
          data-aos="fade-up"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionHeader
                title={
                  <span>
                    Personal Projects Simplified
                    {/* <br />
                    <Typography component="span" variant="inherit" color="primary">
                      to build an app quickly.
                    </Typography> */}
                  </span>
                }
                // subtitle="TheFront styles and extends Material-UI components, but also included brand new landing page focused components."
                subtitle="Turn any list into a mini project, focus on getting things done and avoid the noise of bloated apps."
                align="left"
                fadeUp
                disableGutter
                titleVariant="h3"
              />
            </Grid>
            {/* <Grid item xs={12}>
              <div className={classes.placementGrid}>
                <div>
                  <CountUpNumber
                    end={400}
                    label="Components"
                    textColor="primary"
                    suffix="+"
                  />
                </div>
                <div className={classes.placementGridItemMiddle}>
                  <CountUpNumber
                    end={100}
                    label="Satisfaction"
                    textColor="primary"
                    suffix="%"
                  />
                </div>
                <div>
                  <CountUpNumber
                    end={5.0}
                    label="Review Score"
                    textColor="primary"
                  />
                </div>
              </div>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Features;
